import React, { useEffect, useMemo, useState } from 'react'

import { motion } from 'framer-motion'
import Image from 'next/image'
import { JupiterSwapForm, useJupiterTerminalScript } from 'swap-form'

import useTrans from '@/hooks/use-trans'
import { Container as LayoutContainer } from '@/layouts/components'
import { BUY_RAYDIUM_URL } from '@/root/src/constants'
import { fetchPriceInfo } from '@/root/src/utils/birdeye'
import { trackAmplitude } from 'shared-utilities'
import { fadeIn, flyIn } from 'shared-utilities'
import { ImageName, staticImportImage } from '@/utils/static-import-image'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'

const LegacySwapComponent = ({ tokenPrice, priceChangeColor, priceChange }: any) => {
  const trans = useTrans()
  const handleClickBuyPamp = () => {
    trackAmplitude('go_to_buy_pamp', { product: 'pamp' })
  }

  return (
    <Box className="bg-primary-bg-light rounded-xl px-6 pt-8 pb-16">
      <Stack className="max-sm:flex-column sm:flex-row sm:justify-between gap-6">
        <Stack className="flex-row items-center gap-4">
          <Image
            src={staticImportImage(ImageName.PAMP_TOKEN_LOGO)}
            alt={ImageName.PAMP_TOKEN_LOGO}
            className="w-[3.5rem] h-[3.5rem]"
          />
          <Typography className="text-primary-text font-normal text-2xl font-rubik-mono">
            PAMP
          </Typography>
        </Stack>
        <Stack className="flex-column gap-2">
          <Typography className="text-left text-[2rem] leading-[2.6rem] font-bold inline-block">
            ${tokenPrice}
          </Typography>
          <Stack className="flex-row gap-5">
            <Typography className={`${priceChangeColor} text-2xl leading-[2.25rem]`}>
              {priceChange}%
            </Typography>
            <Typography className="text-primary-text text-2xl leading-[2.25rem]">(24h)</Typography>
          </Stack>
        </Stack>
      </Stack>
      <div className="w-full relative mx-auto">
        <Button
          disableRipple
          className="rounded-xl	bg-secondary py-6 text-2xl font-semibold text-primary-text-dark normal-case absolute w-full lg:w-[60%] -bottom-[6.5rem] lg:left-[20%] 2xl:w-[55%] 2xl:left-[22.5%]"
          href={BUY_RAYDIUM_URL}
          target="_blank"
          rel="noreferrer noopener"
          onClick={handleClickBuyPamp}
        >
          {trans.swap.buy_on_raydium}
        </Button>
      </div>
    </Box>
  )
}

const Swap = () => {
  const { scriptLoaded } = useJupiterTerminalScript()
  const trans = useTrans()
  const [change24h, setChange24h] = useState(0.0)
  const [tokenPrice, setTokenPrice] = useState(0.0)
  const formattedTokenPrice = useMemo(
    () => tokenPrice.toLocaleString(undefined, { maximumFractionDigits: 9 }),
    [tokenPrice],
  )
  const formattedPriceChange = useMemo(() => {
    let str = change24h.toLocaleString(undefined, { maximumFractionDigits: 2 })
    if (change24h > 0) {
      str = `+${str}`
    }
    return str
  }, [change24h])
  const priceChangeColor = useMemo(
    () => (change24h >= 0 ? 'text-[#1AB800]' : 'text-[#b80000]'),
    [change24h],
  )
  const fetchPrice = async () => {
    if (!process.env.NEXT_PUBLIC_TOKEN_ADDRESS) return

    const priceInfo = await fetchPriceInfo(process.env.NEXT_PUBLIC_TOKEN_ADDRESS)
    return priceInfo
  }

  useEffect(() => {
    fetchPrice().then((priceInfo) => {
      if (!priceInfo) return

      if (priceInfo?.currentPrice) setTokenPrice(priceInfo.currentPrice)
      if (priceInfo?.priceChange) setChange24h(priceInfo.priceChange)
    })
  }, [])

  return (
    <Box
      className="relative pt-20"
      id="swap"
      sx={{
        '&#swap.MuiBox-root': {
          '@media (max-height: 780px)': {
            marginTop: '2rem !important',
            minHeight: 'calc(90vh + 410px) !important',
          },
          '@media (max-height: 700px)': {
            marginTop: '3rem !important',
            minHeight: 'calc(95vh + 410px) !important',
          },
        },
      }}
    >
      <LayoutContainer className="overflow-visible">
        <Box className="bg-primary-bg-medium min-h-[100dvh] relative">
          <Stack className="flex-column justify-center gap-10">
            <motion.div
              initial="hidden"
              viewport={{ once: true, amount: 0.25 }}
              whileInView="visible"
              variants={flyIn({ direction: 'right', duration: 0.75 })}
            >
              <Typography className="text-primary-text font-normal xl:text-[72px] xl:leading-[96.48px] font-rubik-mono sm:text-center text-left text-[2.7rem] leading-[3.5rem]">
                {trans.swap.title}
              </Typography>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.25 }}
              variants={fadeIn({ duration: 0.25 })}
              className="flex justify-center relative z-50"
            >
              <LayoutContainer className="px-0 lg:max-w-[60%] 2xl:max-w-[40vw]">
                {process.env.NEXT_PUBLIC_USING_JUPITER_TERMINAL === 'true' &&
                process.env.NEXT_PUBLIC_TOKEN_ADDRESS ? (
                  <JupiterSwapForm
                    scriptLoaded={scriptLoaded}
                    outputMint={process.env.NEXT_PUBLIC_TOKEN_ADDRESS || ''}
                    endpoint={process.env.NEXT_PUBLIC_SOLANA_ENDPOINT || ''}
                    containerStyles={{
                      backgroundColor: '#304256',
                      padding: '1.25rem',
                      borderRadius: '1rem',
                      maxWidth: '500px',
                    }}
                  />
                ) : (
                  <LegacySwapComponent
                    tokenPrice={formattedTokenPrice}
                    priceChange={formattedPriceChange}
                    priceChangeColor={priceChangeColor}
                  />
                )}
              </LayoutContainer>
            </motion.div>
          </Stack>
        </Box>
      </LayoutContainer>
      <Box className="absolute bottom-0 z-40 left-0">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={flyIn({ direction: 'up', duration: 0.75 })}
          className="relative -top-[6rem] xl:top-[5rem]"
        >
          <Image
            src={staticImportImage(ImageName.CLOUD2)}
            alt={ImageName.CLOUD2}
            className="absolute z-10 -scale-x-[1] w-[537px] h-[596px] -top-[6rem] -right-[18rem] lg:-top-[28rem] lg:-right-[10rem]"
          />
          <Image
            src={staticImportImage(ImageName.CLOUD2)}
            alt={ImageName.CLOUD2}
            className="hidden lg:block absolute z-10 w-[571px] h-[606px] -left-[23rem] -top-[30rem] rotate-[30deg]"
          />
          <Image
            src={staticImportImage(ImageName.PAMP_CAT_SHOPING)}
            alt={ImageName.PAMP_CAT_SHOPING}
            className="absolute z-20 w-[14.37rem] h-[15.12rem] lg:w-[20rem] lg:h-[22rem] lg:-top-[10rem] xl:w-[27.4rem] xl:h-[28.9rem] xl:-top-[16rem] xl:left-0 top-[1rem]"
          />
          <Image
            src={staticImportImage(ImageName.BACKGROUND1)}
            alt={ImageName.BACKGROUND1}
            className="absolute z-[11] max-sm:w-[830px] max-sm:h-[353px] sm:h-[341.5px] -left-[20rem] max-sm:top-[5rem] sm:top-[1rem] lg:-left-0 lg:w-[1443.5px] 2xl:w-full"
          />
          <Box className="flex justify-center relative z-20 md:w-[100vw] xl:-top-[10rem]">
            <Grid
              container
              alignItems="center"
              className="lg:w-[48rem] xl:mt-[24rem] mt-[17rem] max-sm:justify-start justify-center xl:gap-x-12"
              rowGap={1.5}
              columnGap={4}
            >
              {['liquidity_pool_burned', 'contract_ownership_renounced'].map((key) => (
                <Grid item xs="auto" container alignItems="center" key={key}>
                  <Grid item xs="auto" className="z-20">
                    <Image
                      src={staticImportImage(ImageName.MARK_CHECK_SHIELD)}
                      alt={ImageName.MARK_CHECK_SHIELD}
                      className="lg:w-[57px] lg:h-[65px] w-[50px] h-[56px]"
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <Typography className="lg:text-xl font-semibold text-white bg-primary-text-dark rounded-xl py-2 pl-9 pr-8 m-[-20px]">
                      {trans.swap[key]}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>
        </motion.div>
      </Box>
    </Box>
  )
}

export default Swap
