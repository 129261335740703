import React from 'react'

import { motion } from 'framer-motion'
import Image from 'next/image'

import PampTokenSocials from '@/root/src/components/base/pamp_token_socials'
import useTrans from '@/root/src/hooks/use-trans'
import { Container as LayoutContainer } from '@/root/src/layouts/components'
import {
  fadeIn,
  floating,
  flyIn,
} from 'shared-utilities'
import {
  ImageName,
  staticImportImage,
} from '@/root/src/utils/static-import-image'
import CloseIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material'

const Hero = () => {
  const trans = useTrans()
  const [openSnackbar, setOpenSnackbar] = React.useState(false)
  const onCopy = () => {
    navigator.clipboard.writeText(process.env.NEXT_PUBLIC_TOKEN_ADDRESS || '')
    setOpenSnackbar(true)
  }

  return (
    <LayoutContainer>
      <Snackbar
        className='fixed bottom-[1rem] w-[50%] min-w-[250px]'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
        message={trans.hero.copied}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpenSnackbar(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <Stack className="flex-column justify-between overflow-hidden w-full relative z-50 gap-[30px] pt-[5.5rem]">
        <div className="block lg:hidden">
          <PampTokenSocials />
        </div>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={flyIn({ direction: 'down', duration: 0.75 })}
        >
          <Typography className="text-primary-text font-normal text-[2.8rem] leading-[3.6rem] font-rubik-mono text-left md:text-[4.75rem] md:leading-[6.25rem] md:text-center">
            {trans.hero.title}
          </Typography>
        </motion.div>
        <motion.div
          initial="hidden"
          animate={['visible', 'floating']}
          variants={{
            ...flyIn({ direction: 'down', duration: 0.75, delay: 0.5 }),
            ...floating({ axis: 'y', duration: 1.5, delay: 0.5 }),
          }}
          className="m-auto"
        >
          <Image
            src={staticImportImage(ImageName.PAMP_CAT_BALLOON)}
            alt={ImageName.PAMP_CAT_BALLOON}
            className="w-[20.9rem] h-[20rem] 2xl:w-[30rem] 2xl:h-[29rem]"
          />
        </motion.div>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={fadeIn({ duration: 0.25 })}
        >
          <Stack className="flex-column gap-4">
            <Typography
              className="text-2xl leading-[36px] md:leading-[3rem] text-center text-primary-text"
              dangerouslySetInnerHTML={{ __html: trans.hero.subTitle }}
            />
            <Container className="max-w-[47rem] mb-10 px-0">
              <Box className="bg-primary-bg-light rounded-xl border-dashed border py-5 px-4">
                <Grid
                  container
                  columns={16}
                  columnGap={2}
                  rowGap={2}
                  alignItems="center"
                  className="items-center justify-center sm:justify-between"
                >
                  <Grid item xs={3} sm={1.5}>
                    <Image
                      src={staticImportImage(ImageName.PAMP_TOKEN_LOGO)}
                      alt={ImageName.PAMP_TOKEN_LOGO}
                      className="w-[3.5rem] h-[3.5rem]"
                    />
                  </Grid>
                  <Grid item xs={12} sm={11}>
                    <Stack className="gap-2">
                      <Typography className="text-sm">{trans.hero.tokenAddress}</Typography>
                      <Typography className="font-bold text-base break-words">
                        <Link
                          href={`https://solscan.io/token/${process.env.NEXT_PUBLIC_TOKEN_ADDRESS}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary-text-dark no-underline"
                        >
                          {process.env.NEXT_PUBLIC_TOKEN_ADDRESS}
                        </Link>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={16} sm={2}>
                    <Button
                      className="text-primary-text-dark hover:bg-primary-bg-medium hover:text-white w-full rounded-xl p-2"
                      onClick={onCopy}
                    >
                      <Stack className="flex-row gap-1.5 justify-between items-center normal-case">
                        <Typography className="text-base font-normal">{trans.hero.copy}</Typography>
                        <ContentCopyIcon />
                      </Stack>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Stack>
        </motion.div>
      </Stack>
      <Box className="min-w-[100%] min-h-[100%] absolute left-0 top-0 z-40 overflow-x-clip">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={flyIn({ direction: 'right', duration: 1, delay: 0.25 })}
          className="absolute top-0 left-0 hidden lg:block"
        >
          <Image
            src={staticImportImage(ImageName.CLOUD1)}
            alt={ImageName.CLOUD1}
            className="absolute top-[15rem] -left-[10rem] w-[33.2rem] h-[28.5rem]"
          />
        </motion.div>
        <motion.div
          initial="hidden"
          animate={['visible', 'floating']}
          className="absolute top-0 left-0 hidden lg:block"
          variants={{
            ...flyIn({ direction: 'right', duration: 1 }),
            ...floating({ axis: 'y', offset: 3, duration: 1.5, delay: 0.5 }),
          }}
        >
          <Image
            src={staticImportImage(ImageName.PLANET)}
            alt={ImageName.PLANET}
            className="absolute w-[12.1rem] h-[9.9rem] left-[5rem] top-[20rem]"
          />
        </motion.div>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={flyIn({ direction: 'left', duration: 1, delay: 0.25 })}
          className="absolute top-0 right-0"
        >
          <Image
            src={staticImportImage(ImageName.CLOUD4)}
            alt={ImageName.CLOUD4}
            className="absolute w-[34.8rem] h-[37.3rem] -right-[8rem] top-[15rem]"
          />
        </motion.div>
        <motion.div
          initial="hidden"
          animate={['visible', 'floating']}
          variants={{
            ...flyIn({ direction: 'left', duration: 1 }),
            ...floating({ axis: 'y', offset: 3, duration: 1.5, delay: 0.5 }),
          }}
          className="absolute top-0 right-0 hidden lg:block"
        >
          <Image
            src={staticImportImage(ImageName.PAMP_CAT_AIRPLANE)}
            alt={ImageName.PAMP_CAT_AIRPLANE}
            className="absolute w-[7.5rem] h-[7rem] right-[8rem] top-[25rem]"
          />
        </motion.div>
      </Box>
    </LayoutContainer>
  )
}

export default Hero
