import React from 'react'

import { motion } from 'framer-motion'
import Image from 'next/image'

import useTrans from '@/hooks/use-trans'
import { flyIn } from 'shared-utilities'
import { ImageName, staticImportImage } from '@/root/src/utils/static-import-image'
import { trackAmplitude } from 'shared-utilities'
import { Box, Button, Container, Stack, Typography } from '@mui/material'

const Roadmap = () => {
  const trans = useTrans()

  const handleBuyPampClick = () => {
    trackAmplitude('click_buy_pamp_button', { product: 'pamp' }, { location: 'roadmap' })
  }

  return (
    <Box
      className="min-h-[100dvh] bg-no-repeat bg-[length:200vw_40rem] bg-[left_-70vw_bottom_-4rem] xl:bg-[length:150vw_150dvh] xl:bg-[left_-40vw_top_-35vh] 2xl:bg-[length:150vw_180dvh] 2xl:bg-[left_-40vw_top_-75vh] bg-[url('/images/background2.png')] pt-[55px] lg:pt-[82px]"
      id="roadmap"
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.25 }}
        variants={flyIn({ direction: 'right', duration: 0.75 })}
        className="mb-[64px] xl:w-[70%]"
      >
        <Stack className="flex-column justify-center items-center gap-[46px] lg:gap-[40px]">
          <Typography className="text-primary-text font-rubik-mono font-normal text-[48px] leading-[64.32px] lg:text-[72px] lg:leading-[96.48px] text-center">
            {trans.roadmap.title}
          </Typography>
          <Image
            src={staticImportImage(ImageName.ROADMAP_VECTOR)}
            alt={ImageName.ROADMAP_VECTOR}
            className="w-[343px] h-[1rem]"
          />
        </Stack>
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.25 }}
        variants={flyIn({ direction: 'right', duration: 0.75 })}
      >
        <Stack className="flex-column justify-center items-center gap-16 pb-8 lg:flex-row lg:gap-0 lg:items-start xl:justify-center xl:w-[70%] xl:gap-6 relative z-50">
          {trans.roadmap.content.map((content: any, index: any) => (
            <Container
              className="px-[39.5px] overflow-x-visible lg:p-0 lg:w-[30%] xl:w-[27%] xl:mx-0"
              key={index}
            >
              <Box className="bg-white p-4 rounded-3xl shadow-[10px_10px_0_0_rgba(0,77,27,1)] px-6 pb-6">
                <Box className="rounded-lg bg-primary-bg-dark px-[40px] py-[8px] flex items-center justify-center mx-[50px] xl:mx-[5px] mt-[-2.2rem]">
                  <Typography className="text-white font-bold text-[20px] leading-[32px]">
                    {content.phase_number}
                  </Typography>
                </Box>
                <Typography
                  className={`text-secondary text-center font-normal mt-4 font-rubik-mono text-[32px] leading-[42.88px] ${content.title_space}`}
                >
                  {content.title}
                </Typography>
                <ul className="text-primary-text md:font-bold md:text-sm">
                  {content.list_items.map((item: any, index: any) => (
                    <li
                      className="[&:not(:last-child)]:mb-4"
                      key={`${content.phase_number}-item-${index}`}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </Box>
            </Container>
          ))}
        </Stack>
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.25 }}
        variants={flyIn({ direction: 'right', duration: 0.5 })}
      >
        <Stack className="flex-column gap-6 items-center mt-[64px] xl:mt-[32px] lg:flex-row lg:justify-center xl:w-[70%]">
          <Typography className="text-xl font-bold xl:text-white xl:text-xl">
            {trans.roadmap.question}
          </Typography>
          <Button
            className="bg-secondary text-primary-text-dark rounded-xl font-bold text-2xl py-4 px-10 normal-case w-fit"
            href="#swap"
            onClick={handleBuyPampClick}
          >
            Buy PAMP
          </Button>
        </Stack>
      </motion.div>
      <motion.div
        className="relative z-10"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.25 }}
        variants={flyIn({ direction: 'left', duration: 0.5 })}
      >
        <Image
          src={staticImportImage(ImageName.PAMP_CAT_CYCLING)}
          alt={ImageName.PAMP_CAT_CYCLING}
          className="w-[15.5rem] h-[16.13rem] relative left-[40vw] mt-[74px] xl:absolute xl:w-[505px] xl:h-[524px] xl:-top-[35rem] xl:left-[calc(100vw-505px)]"
        />
      </motion.div>
    </Box>
  )
}

export default Roadmap
