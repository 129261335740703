import { memo } from 'react'

import MenuIcon from '@mui/icons-material/Menu'
import {
  IconButton,
  Stack,
} from '@mui/material'

import BuyPampButton from './buy_pamp_button'

interface PampTokenActionProps {
  handleClickMenu: () => void
}

const PampTokenAction = (props: PampTokenActionProps) => {
  const { handleClickMenu } = props

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      className="sm:w-fit"
    >
      <BuyPampButton />
      <IconButton
        className="block lg:hidden text-white mr-2 rounded-lg p-3/2"
        aria-label="open menu"
        edge="start"
        onClick={handleClickMenu}
      >
        <MenuIcon fontSize="medium" />
      </IconButton>
    </Stack>
  )
}

export default memo(PampTokenAction)
