import React, { useCallback, useMemo, useState } from 'react'

import { Container } from '@/layouts/components'
import MainMenu from '@/root/src/components/base/main_menu'
import PampTokenAction from '@/root/src/components/base/pamp_token_action'
import PampTokenLogo from '@/root/src/components/base/pamp_token_logo'
import { SocialsButtonsBase } from 'shared-components'
import PampTokenSocials from '@/root/src/components/base/pamp_token_socials'
import { Box, SwipeableDrawer } from '@mui/material'

const defaultMenus = [
  {
    name: 'about',
    link: '#about',
  },
  {
    name: 'swap',
    link: '#swap',
  },
  {
    name: 'tokenomics',
    link: '#pampnomics',
  },
  {
    name: 'roadmap',
    link: '#roadmap',
  },
]

const Header = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const toggleDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent | React.TouchEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setIsOpenMenu(open)
    },
    [],
  )

  const handleClickMenu = useCallback(() => {
    setIsOpenMenu(true)
  }, [setIsOpenMenu])

  const actionProps = useMemo(() => {
    return { handleClickMenu }
  }, [handleClickMenu])

  const drawer = useMemo(
    () => (
      <SwipeableDrawer
        anchor="right"
        open={isOpenMenu}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={15}
        hysteresis={1}
        className="z-[99999]"
        sx={{
          '& .MuiPaper-root': {
            width: '100%',
          },
        }}
      >
        <MainMenu toggleDrawer={toggleDrawer} />
      </SwipeableDrawer>
    ),
    [isOpenMenu, toggleDrawer],
  )

  return (
    <Box className="fixed top-0 md:py-0 bg-primary-bg-dark w-full z-[9999] py-4">
      <Container className="flex justify-between items-center">
        <PampTokenLogo />
        <div className="flex items-center justify-between">
          <div className="lg:flex hidden items-center justify-between gap-12">
            <PampTokenSocials />
            <SocialsButtonsBase
              classBox=""
              classContainer="flex flex-col lg:flex-row p-0 justify-center items-center gap-6"
              classButtons="justify-center sm:bg-transparent text-white rounded-lg font-semibold capitalize hover:text-ps-secondary text-base p-0"
              buttons={defaultMenus}
              closeDrawer={toggleDrawer(false)}
            />
          </div>
          <PampTokenAction {...actionProps} />
        </div>
      </Container>
      {drawer}
    </Box>
  )
}

export default Header
