import React, { useRef } from 'react'

import {
  motion,
  useScroll,
  useTransform,
} from 'framer-motion'
import Image from 'next/image'

import { Container as LayoutContainer } from '@/layouts/components'
import useTrans from '@/root/src/hooks/use-trans'
import { flyIn } from 'shared-utilities'
import {
  ImageName,
  staticImportImage,
} from '@/root/src/utils/static-import-image'
import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'

const About = () => {
  const skatingCatRef = useRef<HTMLDivElement>(null)
  const { scrollYProgress } = useScroll({
    target: skatingCatRef,
    offset: ['start end', 'end start'],
  })
  const lgScreen = useMediaQuery('(min-width: 1024px)')
  const offsetX = useTransform(scrollYProgress, [0, 1], ['20%', lgScreen ? '-10%' : '-50%'])
  const offsetY = useTransform(scrollYProgress, [0, 1], [0, 60])
  const trans = useTrans()

  return (
    <LayoutContainer className="overflow-visible">
      <Box className="bg-primary-bg-medium min-h-[100svh] lg:min-h-[90vh] relative pt-[10rem]" id="about">
        <Stack className="flex-column w-full lg:w-[40%] relative z-50 gap-6">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.25 }}
            variants={flyIn({direction: 'down', duration: 0.75})}
          >
            <Typography className="text-primary-text text-[3rem] leading-[4.02rem] font-rubik-mono">
              {trans.about.title}
            </Typography>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.25 }}
            variants={flyIn({direction: 'down', duration: 0.75})}
          >
            <Stack className="flex-column text-justify gap-8">
              <Typography dangerouslySetInnerHTML={{ __html: trans.about.content1 }} />
              <Typography dangerouslySetInnerHTML={{ __html: trans.about.content2 }} />
            </Stack>
          </motion.div>
        </Stack>
        <Box className="relative -left-[1rem] -top-[2rem] w-[100svw] min-h-[60vh] z-40 lg:-top-[15rem] xl:-top-[30rem] xl:min-h-[70vh] 2xl:-top-[27rem]">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={flyIn({direction: 'right', duration: 0.75})}
          >
            <Image src={staticImportImage(ImageName.BUILDING)} alt={ImageName.BUILDING} className="absolute w-[34.4rem] h-[19.65rem] -right-[20rem] top-[5rem] lg:w-[57rem] lg:h-[32rem] xl:w-[1361.45px] xl:h-[768px] xl:-right-[27rem] xl:top-[-3rem]" />
            <Image src={staticImportImage(ImageName.CLOUD2)} alt={ImageName.CLOUD2} className="absolute -left-[17rem] xl:-top-[3rem]" />
            <Image src={staticImportImage(ImageName.SIGN_BOARD_1)} alt={ImageName.SIGN_BOARD_1} className="absolute w-[6.61rem] h-[10.68rem] top-[19rem] -left-[1.5em] -rotate-[5deg] lg:w-[8.61rem] lg:h-[12.68rem] lg:top-[30rem] lg:left-[10rem] xl:w-[207px] xl:h-[303px] xl:top-[32rem] xl:left-[8rem]" />
            <Image src={staticImportImage(ImageName.SIGN_BOARD_2)} alt={ImageName.SIGN_BOARD_2} className="absolute w-[5.11rem] h-[8.18rem] top-[20.2rem] left-[3.5em] lg:w-[7.11rem] lg:h-[10.18rem] lg:top-[31.5rem] lg:left-[16rem] xl:w-[160px] xl:h-[256px] xl:top-[34rem] xl:left-[18rem]" />
            <Image src={staticImportImage(ImageName.SKATE_ROAD)} alt={ImageName.SKATE_ROAD}
              className="absolute w-[46.7rem] h-[12.3rem] top-[18.8rem] -left-[10rem] min-[584px]:w-[48rem] min-[584px]:left-0 min-[584px]:top-[20.2rem] md:w-[70rem] md:top-[18rem] lg:w-[90rem] lg:h-[23.7rem] lg:top-[24rem] lg:-rotate-[2deg] lg:-left-[10rem] xl:top-[32rem] xl:rotate-[1deg] xl:w-full xl:-left-[5rem] min-[1800px]:-left-[9rem] min-[1800px]:scale-x-[1.5]"
            />
          </motion.div>
          <motion.div
            ref={skatingCatRef}
            style={{ translateX: offsetX, translateY: offsetY }}
            transition={{ ease: 'linear' }}
          >
            <Image src={staticImportImage(ImageName.PAMP_CAT_SKATING)} alt={ImageName.PAMP_CAT_SKATING} className="absolute w-[13.56rem] h-[15.625rem] right-[0.5rem] top-[11rem] lg:top-[15rem] lg:w-[20rem] lg:h-[24rem] lg:right-[7rem] xl:w-[30rem] xl:h-[36.7rem] xl:top-[12rem] xl:right-[14rem]" />
          </motion.div>
        </Box>
      </Box>
    </LayoutContainer>
  )
}

export default About
