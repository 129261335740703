import { BIRDEYE_PRICE_API } from '@/constants/index'

const fetchBirdeyePriceHistory = async (tokenAddress: string) => {
  try {
    const reqHeaders: any = {
      'x-chain': 'solana',
      'X-API-KEY': process.env.NEXT_PUBLIC_BIRDEYE_API_KEY,
      'Content-Type': 'application/json',
    }
    const timeTo = new Date()
    const timeFrom = new Date(timeTo)
    timeFrom.setDate(timeTo.getDate() - 1)

    const reqParams: any = {
      address: tokenAddress,
      address_type: 'token',
      type: '15m',
      time_from: Math.floor(timeFrom.getTime() / 1000),
      time_to: Math.floor(timeTo.getTime() / 1000),
    }
    const query = Object.keys(reqParams)
      .map((key) => `${key}=${reqParams[key]}`)
      .join('&')

    const response = await fetch(`${BIRDEYE_PRICE_API}?${query}`, {
      method: 'GET',
      headers: reqHeaders,
    })
    const data = await response.json()

    return data.data.items
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error('Cannot fetch price', error)

    return []
  }
}

const getStartOfDayTimestamp = () => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return Math.floor(today.getTime() / 1000)
}

export const fetchPriceInfo = async (tokenAddress: string) => {
  const data = await fetchBirdeyePriceHistory(tokenAddress)
  if (data.length === 0) return

  const currentPrice = data[data.length - 1].value
  const startOfDayTimestamp = getStartOfDayTimestamp()
  const yesterdayPrice = data.find((item: any) => item.unixTime <= startOfDayTimestamp)?.value || data[0].value

  const priceChange = (currentPrice * 1.0 / yesterdayPrice - 1) * 100

  return { currentPrice, priceChange }
}
