import React, { useMemo } from 'react'

import Image from 'next/image'

import useTrans from '@/root/src/hooks/use-trans'
import {
  ImageName,
  staticImportImage,
} from '@/root/src/utils/static-import-image'
import {
  Link,
  Typography,
} from '@mui/material'

interface PampTokenLogoProps {
  iconOnly?: boolean
}

const PampTokenLogo = (props: PampTokenLogoProps) => {
  const trans = useTrans()
  const { iconOnly } = props
  const pampTokenLogo = useMemo(() => {
    return iconOnly
      ? staticImportImage(ImageName.PAMP_TOKEN_LOGO)
      : staticImportImage(ImageName.PAMP_TOKEN_LOGO)
  }, [iconOnly])
  return (
    <Link href="/" data-testid="header-home-trade" className="no-underline mr-[2rem] h-[40px] sm:h-auto">
      <div className='sm:flex hidden flex-row justify-center items-center'>
        <Image className="inline mr-2" src={pampTokenLogo} height={36} alt={ImageName.PAMP_TOKEN_LOGO} />
        <Typography className="text-white uppercase font-black">{trans.header.title}</Typography>
      </div>
      <Image className="sm:hidden inline mr-2" src={staticImportImage(ImageName.PAMP_CAT_LOGO)} height={40} alt={ImageName.PAMP_CAT_LOGO} />
    </Link>
  )
}

export default PampTokenLogo
