import {
  memo,
  useMemo,
} from 'react'

import Image from 'next/image'
import { trackAmplitude } from 'shared-utilities'

import {
  ImageName,
  staticImportImage,
} from '@/root/src/utils/static-import-image'
import {
  List,
  ListItem,
  ListItemButton,
} from '@mui/material'

const PampTokenSocials = () => {
  const defaultMenus = useMemo(() => {
    return [
      {
        link: 'https://x.com/pamp_cat',
        icon: ImageName.TWITTER,
        trackingName: 'twitter',
      },
      {
        link: 'https://t.me/pamp_cat',
        icon: ImageName.TELEGRAM,
        trackingName: 'telegram',
      },
      {
        link: 'https://www.dextools.io/app/en/solana/pair-explorer/3Dj8Hn8y83NS3W9yGnNBVRoTy4siuhZ3cutrzMXwCiSP',
        icon: ImageName.DEXTOOL_ICON,
        trackingName: 'dextool',
      },
      {
        link: 'https://birdeye.so/token/Ec5rX5Ctca6hA3sh6EBJWGH8fESk5F1HnypXNZ6zYaRw?chain=solana',
        icon: ImageName.BIRDEYE_ICON,
        trackingName: 'birdeye',
      },
      {
        link: 'https://dexscreener.com/solana/3dj8hn8y83ns3w9ygnnbvroty4siuhz3cutrzmxwcisp',
        icon: ImageName.DEXSCREENER_ICON,
        trackingName: 'dexscreener',
      },
      {
        link: `https://solscan.io/token/${process.env.NEXT_PUBLIC_TOKEN_ADDRESS}`,
        icon: ImageName.SOLSCAN_ICON,
        trackingName: 'solscan',
      },
      {
        link: 'https://www.geckoterminal.com/solana/pools/3Dj8Hn8y83NS3W9yGnNBVRoTy4siuhZ3cutrzMXwCiSP',
        icon: ImageName.COINGECKO_ICON,
      },
      {
        link: 'https://moontok.io/coins/pamp-cat',
        icon: ImageName.MOONTOK_ICON,
      },
      // {
      //   link: '#',
      //   icon: ImageName.COIN_MARKETCAP_ICON,
      // },
    ]
  }, [])
  const handleClickSocial = (trackingName?: string) => {
    if (!trackingName) return

    trackAmplitude('click_social_link', { product: 'pamp' }, { social: trackingName })
  }

  return (
    <List
      role="menubar"
      className="flex justify-between uppercase gap-4 md:justify-center md:gap-6"
    >
      {defaultMenus.map((menu, index) => (
        <ListItem key={index} role="none" className="sm:w-fit flex justify-center px-0 py-0">
          <ListItemButton
            role="menuitem"
            disableRipple={true}
            className="max-w-[24px] max-h-[24px] justify-center sm:bg-transparent rounded-lg font-semibold hover:text-ps-secondary text-base normal-case p-0"
            component="a"
            href={menu.link}
            onClick={() => handleClickSocial(menu.trackingName)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src={staticImportImage(menu.icon)} alt={menu.icon} width={24} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}

export default memo(PampTokenSocials)
