import Image from 'next/image'

import { Container } from '@/layouts/components'
import useTrans from '@/root/src/hooks/use-trans'
import {
  ImageName,
  staticImportImage,
} from '@/root/src/utils/static-import-image'
import {
  Box,
  Typography,
} from '@mui/material'

const Footer = () => {
  const trans = useTrans()

  return (
    <>
      <Box className="w-full bg-primary-text-dark">
        <Container className="py-6 flex items-center justify-between gap-4 md:flex-row flex-col">
          <Typography
            variant="h6"
            className="text-base font-normal text-white"
          >
            {trans.footer.copyright}
          </Typography>
          <div className="flex items-center gap-6">
            <Typography
              variant="body2"
              className="text-base font-bold text-white"
            >
              {trans.footer.build_with}
            </Typography>
            <Image src={staticImportImage(ImageName.SOLANA)} alt={ImageName.SOLANA} width={40} />
          </div>
        </Container>
      </Box>
    </>
  )
}

export default Footer
