import React, { memo } from 'react'

import BuyPampButton from '@/root/src/components/base/buy_pamp_button'
import PampTokenLogo from '@/root/src/components/base/pamp_token_logo'
import PampTokenSocials from '@/root/src/components/base/pamp_token_socials'
import { SocialsButtonsBase } from 'shared-components'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Paper, Stack } from '@mui/material'

const defaultMenus = [
  {
    name: 'about',
    link: '#about',
  },
  {
    name: 'swap',
    link: '#swap',
  },
  {
    name: 'tokenomics',
    link: '#pampnomics',
  },
  {
    name: 'roadmap',
    link: '#roadmap',
  },
]

type MainMenuProps = {
  toggleDrawer: (
    open: boolean,
  ) => (event: React.KeyboardEvent | React.MouseEvent | React.TouchEvent) => void
}

const MainMenu = (props: MainMenuProps) => {
  const { toggleDrawer } = props

  return (
    <Stack
      className="bg-primary-bg-dark h-full w-full border-none z-[9999]"
      justifyContent="space-between"
      role="presentation"
    >
      <Paper elevation={0} className="bg-primary-bg-dark border-none">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          justifyItems="center"
          className="w-full p-3"
        >
          <PampTokenLogo />
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            className="sm:w-fit"
          >
            <BuyPampButton />
            <IconButton
              className="text-white mr-2 p-1"
              aria-label="open menu"
              edge="start"
              onClick={toggleDrawer(false)}
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Stack>
        </Stack>
      </Paper>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        className="h-full bg-primary-bg-dark"
      >
        <div className="mb-6">
          <PampTokenSocials />
        </div>
        <SocialsButtonsBase
          classBox=""
          classContainer="flex flex-col lg:flex-row p-0 justify-center items-center gap-6"
          classButtons="justify-center sm:bg-transparent text-white capitalize rounded-lg font-semibold hover:text-ps-secondary text-base p-0"
          buttons={defaultMenus}
          closeDrawer={toggleDrawer(false)}
        />
      </Stack>
    </Stack>
  )
}

export default memo(MainMenu)
