import { Box } from '@mui/material'

import Footer from './footer'
import Header from './header'

export const MainLayout = ({ children }: HocProps) => {
  return (
    <>
      <Header />
      <Box className="w-full bg-primary-bg-medium min-h-dvh overflow-x-clip">
        {children}
      </Box>
      <Footer />
    </>
  )
}
