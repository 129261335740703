// eslint-disable-next-line @typescript-eslint/no-explicit-any
const locale: { [key: string]: any } = {
  seo: {
    title: 'PAMP: The Purr-fect Memecoin for Crypto Meme Lords 🐱',
    description: 'Join the adventures of Pamp the cat, inspired by Pepe the Frog! $PAMP is a deflationary memecoin that brings joy and laughter to the crypto world. With a vibrant community, dank memes, and exciting contests, PAMP is revolutionizing meme culture in the blockchain space.',
    thumbnailUrl: '/images/thumbnail.jpg',
  },
  hero: {
    title: "pamp-the pepe's cat",
    subTitle: 'No expectation of financial return. Only for entertainment purpose.',
    tokenAddress: 'PAMP Address',
    copy: 'Copy',
    copied: 'Copied to clipboard',
  },
  menu: {
    about: 'About',
    swap: 'Swap',
    tokenomics: 'Tokenomics',
    roadmap: 'Roadmap',
  },
  header: {
    title: 'Pamp',
    buy_button: 'Buy PAMP',
  },
  footer: {
    copyright: '2024 PAMP All Rights Reserved',
    build_with: 'Build with Solana',
  },
  about: {
    title: 'about',
    content1: "Welcome to the adventurous world of Pamp, the spirited cat who was inspired by the legendary internet meme, Pepe the frog. Under Pepe's mentorship, Pamp learned the art of spreading joy and laughter for community at the core. He then founded <strong>$PAMP</strong> token: <strong>no presale, zero taxes</strong>, and a forever commitment with <strong>burnt liquidity and renounced ownership.</strong>",
    content2: "<strong>$PAMP</strong> represents a movement towards a lighter, more inclusive crypto world. With the backing of Pepe’s legacy and Pamp’s fearless innovation, this token is set to revolutionize the crypto scene. Let's make the crypto world purr with the spirit of memes!",
  },
  swap: {
    title: 'swap',
    buy_on_raydium: 'Buy on Raydium',
    liquidity_pool_burned: 'Liquidity Pool Burned',
    contract_ownership_renounced: 'Contract Ownership Renounced',
  },
  pampnomics: {
    title: 'Pampnomics',
    tooltip1:'510,000,000 tokens allocated to airdrops for the Solana community and later expansion to other networks',
    tooltip2:'290,000,000 tokens allocated to liquidity pool on Raydium for trading',
    tooltip3: '200,000,000 tokens allocated to product development, operation and marketing to ensure the long-term success of Pamp community',
    initialLiquidity: 'Initial Liquidity',
    pampDao: 'PampDAO',
    airdrop: 'Airdrop',
  },
  roadmap: {
    title: 'roadmap',
    content: [
      {
        phase_number: 'Phase 0',
        title: 'PAMP',
        title_space: 'ml-0',
        list_items: [
          '$PAMP official launch on Solana',
          'Just HODL and pamp it',
        ],
      },
      {
        phase_number: 'Phase 1',
        title: 'PAMPPP',
        title_space: 'ml-[1.5em]',
        list_items: [
          'Community airdrops',
          'Coinmarkecap and Coingecko listing',
          'More exchanges listing',
          'Just HODL and pamppp it',
        ],
      },
      {
        phase_number: 'Phase 2',
        title: 'PAMPPPPPPPPP',
        title_space: 'ml-[2em]',
        list_items: [
          'Pampbot official launch',
          'Buy back and burn mechanism',
          'Expansion to other networks',
          'Just HODL and pamppppppp itttttttttttttttttt',
        ],
      },
    ],
    question: 'Ready for a ride to the moon?',

  },
}

export default locale
