import Background1 from '@/assets/background1.png'
import BirdeyeIcon from '@/assets/birdeye-icon.png'
import Building from '@/assets/building.png'
import Cloud1 from '@/assets/cloud1.png'
import Cloud2 from '@/assets/cloud2.png'
import Cloud3 from '@/assets/cloud3.png'
import Cloud4 from '@/assets/cloud4.png'
import Cloud5 from '@/assets/cloud5.png'
import CoinMarketcapIcon from '@/assets/coin-marketcap-icon.png'
import CoingeckoIcon from '@/assets/coingecko-icon.png'
import DexscreenerIcon from '@/assets/dex-screener-icon.png'
import DextoolIcon from '@/assets/dextool-icon.png'
import MarkCheckShield from '@/assets/mark-check-shield.png'
import MoontokIcon from '@/assets/moontok-icon.png'
import PampCatAirplane from '@/assets/pamp-cat-airplane.png'
import PampCatBalloon from '@/assets/pamp-cat-balloon.png'
import PampCatCycling from '@/assets/pamp-cat-cycling.png'
import PampCatLogo from '@/assets/pamp-cat-logo.png'
import PampCatShoping from '@/assets/pamp-cat-shoping.png'
import PampCatSkating from '@/assets/pamp-cat-skating.png'
import PampTokenLogo from '@/assets/pamp-token-logo.png'
import PampnomicsCircle from '@/assets/pampnomics-circle.png'
import Planet from '@/assets/planet.png'
import RoadmapVector from '@/assets/roadmap-vector.png'
import SignBoard1 from '@/assets/sign-board-1.png'
import SignBoard2 from '@/assets/sign-board-2.png'
import SkateRoad from '@/assets/skate-road.png'
import Solana from '@/assets/solana-icon.png'
import SolscanIcon from '@/assets/solscan-icon.png'
import Telegram from '@/assets/telegram.png'
import Twitter from '@/assets/twitter.png'
import Vector from '@/assets/vector.svg'

export const ImageName = {
  PAMP_TOKEN_LOGO: 'pamp-token-logo',
  PAMP_CAT_BALLOON: 'pamp-cat-balloon',
  PAMP_CAT_AIRPLANE: 'pamp-cat-airplane',
  PLANET: 'planet',
  CLOUD1: 'cloud1',
  CLOUD2: 'cloud2',
  CLOUD5: 'cloud5',
  CLOUD3: 'cloud3',
  CLOUD4: 'cloud4',
  TWITTER: 'Twitter',
  TELEGRAM: 'Telegram',
  COIN_MARKETCAP_ICON: 'coin-marketcap-icon',
  SOLSCAN_ICON: 'solscan-icon',
  DEXSCREENER_ICON: 'dexscreener-icon',
  DEXTOOL_ICON: 'dextool-icon',
  BIRDEYE_ICON: 'birdeye-icon',
  COINGECKO_ICON: 'coin-gecko-icon',
  MOONTOK_ICON: 'moontok-icon',
  SOLANA: 'Solana',
  PAMP_CAT_SKATING: 'pamp-cat-skating',
  BUILDING: 'building',
  SKATE_ROAD: 'skate-road',
  SIGN_BOARD_1: 'sign-board1',
  SIGN_BOARD_2: 'sign-board2',
  MARK_CHECK_SHIELD: 'mark-check-shield',
  PAMP_CAT_SHOPING: 'pamp-cat-shoping',
  BACKGROUND1: 'background1',
  PAMPNOMICS_CIRCLE: 'pampnomics-circle',
  VECTOR: 'vector',
  PAMP_CAT_LOGO: 'pamp-cat-logo',
  ROADMAP_VECTOR: 'roadmap-vector',
  PAMP_CAT_CYCLING: 'pamp-cat-cycling',
}

export const staticImportImage = (iconName: string) => {
  switch (iconName) {
    case ImageName.CLOUD1:
      return Cloud1
    case ImageName.CLOUD2:
      return Cloud2
    case ImageName.CLOUD3:
      return Cloud3
    case ImageName.CLOUD4:
      return Cloud4
    case ImageName.CLOUD5:
      return Cloud5
    case ImageName.PLANET:
      return Planet
    case ImageName.BUILDING:
      return Building
    case ImageName.SKATE_ROAD:
      return SkateRoad
    case ImageName.PAMP_CAT_BALLOON:
      return PampCatBalloon
    case ImageName.PAMP_CAT_AIRPLANE:
      return PampCatAirplane
    case ImageName.PAMP_CAT_SKATING:
      return PampCatSkating
    case ImageName.SIGN_BOARD_1:
      return SignBoard1
    case ImageName.SIGN_BOARD_2:
      return SignBoard2
    case ImageName.PAMP_TOKEN_LOGO:
      return PampTokenLogo
    case ImageName.TWITTER:
      return Twitter
    case ImageName.TELEGRAM:
      return Telegram
    case ImageName.COIN_MARKETCAP_ICON:
      return CoinMarketcapIcon
    case ImageName.SOLSCAN_ICON:
      return SolscanIcon
    case ImageName.DEXSCREENER_ICON:
      return DexscreenerIcon
    case ImageName.DEXTOOL_ICON:
      return DextoolIcon
    case ImageName.BIRDEYE_ICON:
      return BirdeyeIcon
    case ImageName.COINGECKO_ICON:
      return CoingeckoIcon
    case ImageName.MOONTOK_ICON:
      return MoontokIcon
    case ImageName.SOLANA:
      return Solana
    case ImageName.MARK_CHECK_SHIELD:
      return MarkCheckShield
    case ImageName.PAMP_CAT_SHOPING:
      return PampCatShoping
    case ImageName.BACKGROUND1:
      return Background1
    case ImageName.PAMPNOMICS_CIRCLE:
      return PampnomicsCircle
    case ImageName.VECTOR:
      return Vector
    case ImageName.PAMP_CAT_LOGO:
      return PampCatLogo
    case ImageName.ROADMAP_VECTOR:
      return RoadmapVector
    case ImageName.PAMP_CAT_CYCLING:
      return PampCatCycling
    default:
      return PampTokenLogo
  }
}
