import { memo } from 'react'

import { BUY_RAYDIUM_URL } from '@/constants/index'
import { trackAmplitude } from 'shared-utilities'

import { Button } from './Button'

const BuyPampButton = () => {
  const handleClick = () => {
    trackAmplitude('click_buy_pamp_button', { product: 'pamp' }, { location: 'main_menu' })
  }

  return (
    <Button
      className="px-4 py-2 mr-2 bg-secondary hover:bg-secondary rounded-[0.75rem] border-solid border border-secondary text-sm font-bold text-primary-text-dark normal-case"
      href={BUY_RAYDIUM_URL}
      target="_blank"
      rel="noreferrer noopener"
      onClick={handleClick}
    >
      Buy PAMP
    </Button>
  )
}

export default memo(BuyPampButton)
