import { useEffect } from 'react'

import { About, Hero, Pampnomics, Roadmap, Swap } from '@/components/home/components'
import { trackAmplitude } from 'shared-utilities'

const Home = () => {
  useEffect(() => {
    trackAmplitude('visit_home_page', { product: 'pamp' })
  }, [])

  return (
    <>
      <Hero />
      <About />
      <Swap />
      <Pampnomics />
      <Roadmap />
    </>
  )
}

export default Home
