import React from 'react'

import { motion } from 'framer-motion'
import Image from 'next/image'

import useTrans from '@/root/src/hooks/use-trans'
import { flyIn } from 'shared-utilities'
import {
  ImageName,
  staticImportImage,
} from '@/root/src/utils/static-import-image'
import {
  Box,
  Stack,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Tooltip, {
  tooltipClasses,
  TooltipProps,
} from '@mui/material/Tooltip'

type TooltipOffset = {
  top?: string
  left?: string
  right?: string
  bottom?: string
}

const TokenomicsTooltip = styled(({ className, ...props }: TooltipProps & { offset?: TooltipOffset }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ offset }) => {
  const mediumScreen = useMediaQuery('(max-width: 1024px)')
  const tooltipStyles: any = {
    fontSize: `${mediumScreen ? '14px' : '18px'} !important`,
  }
  let popperStyles = {}
  if (offset && Object.keys(offset).length > 0) {
    popperStyles = {
      [`&.${tooltipClasses.popperInteractive}`]: {
        ...offset,
      },
    }
  }
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      ...tooltipStyles,
    },
    ...popperStyles,
  }
})

const Pampnomics = () => {
  const trans = useTrans()
  const mediumScreen = useMediaQuery('(max-width: 768px)')

  return (
    <div className="relative min-h-[100dvh]" id="pampnomics">
      <Stack className="flex-column py-24 justify-between items-stretch overflow-hidden w-full relative z-50 gap-[30px]">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.25 }}
          variants={flyIn({ direction: 'down', duration: 0.75 })}
        >
          <Typography className="text-primary-text font-normal text-[2rem] leading-[3.6rem] font-rubik-mono lg:text-[4.5rem] lg:leading-[6.25rem] text-center">
            {trans.pampnomics.title}
          </Typography>
        </motion.div>
        <div className="relative m-auto z-50">
          <TokenomicsTooltip
            title={trans.pampnomics.tooltip1}
            placement={mediumScreen ? 'bottom' : 'right'}
            offset={{
              top: mediumScreen ? '10% !important' : '15% !important',
              left: mediumScreen ? '0' : '-4% !important',
            }}
          >
            <div
              className="absolute bg-[transparent] w-full h-full z-[200]"
              style={{ clipPath: 'polygon(95% 0, 100% 0, 100% 100%, 0 100%, 0 90%)' }}
            ></div>
          </TokenomicsTooltip>
          <TokenomicsTooltip
            title={trans.pampnomics.tooltip2}
            placement={mediumScreen ? 'bottom' : 'top'}
            offset={{
              [mediumScreen ? 'top' : 'bottom']: `${mediumScreen ? '' : '-'}8% !important`,
              left: mediumScreen ? '0' : '-10% !important',
            }}
          >
            <div
              className="absolute bg-[transparent] w-full h-full z-[200]"
              style={{ clipPath: 'polygon(0 0, 93% 0, 48% 43%, 0 24%)' }}
            ></div>
          </TokenomicsTooltip>
          <TokenomicsTooltip
            title={trans.pampnomics.tooltip3}
            placement={mediumScreen ? 'bottom' : 'left'}
            offset={{
              top: '10% !important',
            }}
          >
            <div
              className="absolute bg-[transparent] w-full h-full z-[200]"
              style={{ clipPath: 'polygon(0 26%, 0 87%, 46% 44%)' }}
            ></div>
          </TokenomicsTooltip>

          <Image
            src={staticImportImage(ImageName.PAMPNOMICS_CIRCLE)}
            alt={ImageName.PAMPNOMICS_CIRCLE}
            className="lg:w-[34.375rem] lg:h-[28.75rem] z-[99] w-[18.25rem] h-[15.25rem]"
          />
          <Image
            src={staticImportImage(ImageName.VECTOR)}
            alt={ImageName.VECTOR}
            className="absolute rotate-[-90deg] lg:rotate-[0deg] left-[0.5rem] top-[11rem] lg:top-[6.25rem] lg:left-[0rem]"
          />
          <Typography className="absolute lg:text-2xl text-xl text-primary-text font-bold text-center lg:top-[4rem] lg:left-0 left-[3.5rem] top-[18.5rem]">
            {trans.pampnomics.initialLiquidity}
          </Typography>
          <Image
            src={staticImportImage(ImageName.VECTOR)}
            alt={ImageName.VECTOR}
            className="absolute w-2/5 lg:w-full rotate-[-90deg] lg:rotate-[0deg] left-[-1rem] top-[12rem] lg:top-[16rem] lg:left-[-18rem]"
          />
          <Typography className="absolute lg:text-2xl text-xl text-primary-text font-bold text-center lg:top-[14rem] lg:left-[-8rem] left-0 top-[15.5rem]">
            {trans.pampnomics.pampDao}
          </Typography>
          <Image
            src={staticImportImage(ImageName.VECTOR)}
            alt={ImageName.VECTOR}
            className="absolute w-1/2 lg:w-full rotate-[-90deg] lg:rotate-[180deg] left-[10rem] top-[12rem] lg:top-[17rem] lg:left-[16rem]"
          />
          <Typography className="absolute lg:text-2xl text-xl text-primary-text font-bold text-center lg:top-[15rem] lg:left-[35rem] left-[12.5rem] top-[16.5rem]">
            {trans.pampnomics.airdrop}
          </Typography>
        </div>
      </Stack>
      <Box className="w-[100vw] h-[100dvh] absolute top-0">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.25 }}
          variants={flyIn({ direction: 'left', duration: 1, delay: 0.25 })}
        >
          <Image
            src={staticImportImage(ImageName.CLOUD2)}
            alt={ImageName.CLOUD2}
            className="relative w-[209px] h-[231px] lg:w-[400px] lg:h-[444px] top-[202px] left-[calc(50vw+73px)]"
          />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.25 }}
          variants={flyIn({ direction: 'left', duration: 1, delay: 0.25 })}
          className="absolute top-0 left-0"
        >
          <Image
            src={staticImportImage(ImageName.CLOUD3)}
            alt={ImageName.CLOUD3}
            className="relative w-[216px] h-[230px] lg:w-[437px] lg:h-[464px] top-[250px] lg:top-[300px] left-[calc(50vw-300px)] lg:left-[calc(50vw-550px)]"
          />
        </motion.div>
      </Box>
    </div>
  )
}

export default Pampnomics
